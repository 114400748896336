import React, { useState,useEffect} from 'react';
import styles from './index.module.css'
import Axios from 'axios';

export default function Main({loginName}) {
    return (
        <div><h1>welcome {loginName}</h1>

        <h1 >功能还在开发中 摸摸狗玩吧👇</h1>
        </div>
    );
      
    
  }