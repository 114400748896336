import React from 'react';
import styles from './index.module.css';
import Chatbot from '../../components/chatComponent'
const Chat = () => {

  return (
      <div><Chatbot/></div>
  );
};

export default Chat;